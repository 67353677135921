import logo from "../../assets/logo.webp";
export const Header = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        {/* <svg
          className="header__svg--mob"
          width="425"
          height="90"
          viewBox="0 0 425 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1311_4627)">
            <path
              d="M-15.1797 -10.2857H441.249V80.7857L426.985 67.7755L412.722 80.7857L398.459 67.7755L384.195 80.7857L369.932 67.7755L355.669 80.7857L341.405 67.7755L327.142 80.7857L312.878 67.7755L298.615 80.7857L284.352 67.7755L270.088 80.7857L255.825 67.7755L241.561 80.7857L227.298 67.7755L213.035 80.7857L198.771 67.7755L184.508 80.7857L170.244 67.7755L155.981 80.7857L141.718 67.7755L127.454 80.7857L113.191 67.7755L98.9275 80.7857L84.6641 67.7755L70.4007 80.7857L56.1373 67.7755L41.8739 80.7857L27.6105 67.7755L13.3471 80.7857L-0.916295 67.7755L-15.1797 80.7857V-10.2857Z"
              fill="url(#paint0_linear_1311_4627)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1311_4627"
              x="-19.4654"
              y="-10.2857"
              width="465.001"
              height="99.6429"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.28571" />
              <feGaussianBlur stdDeviation="2.14286" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1311_4627"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1311_4627"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1311_4627"
              x1="213.035"
              y1="80.7857"
              x2="213.035"
              y2="-22.548"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D4059" />
              <stop offset="1" stopColor="#143A6C" />
            </linearGradient>
          </defs>
        </svg> */}

        <svg
          className="header__svg--pc"
          width="1287"
          height="94"
          viewBox="0 0 1287 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1316_5478)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.625 0.589539H430.625H856.625H1282.62V85.5895L1269.31 73.4467L1256 85.5895L1242.69 73.4467L1229.38 85.5895L1216.06 73.4467L1202.75 85.5895L1189.44 73.4467L1176.12 85.5895L1162.81 73.4467L1149.5 85.5895L1136.19 73.4467L1122.88 85.5895L1109.56 73.4467L1096.25 85.5895L1082.94 73.4467L1069.62 85.5895L1056.31 73.4467L1043 85.5895L1029.69 73.4467L1016.38 85.5895L1003.06 73.4467L989.75 85.5895L976.438 73.4467L963.125 85.5895L949.812 73.4467L936.5 85.5895L923.188 73.4467L909.875 85.5895L896.562 73.4467L883.25 85.5895L869.938 73.4467L856.625 85.5895L843.312 73.4467L830 85.5895L816.688 73.4467L803.375 85.5895L790.062 73.4467L776.75 85.5895L763.438 73.4467L750.125 85.5895L736.812 73.4467L723.5 85.5895L710.188 73.4467L696.875 85.5895L683.562 73.4467L670.25 85.5895L656.938 73.4467L643.625 85.5895L630.312 73.4467L617 85.5895L603.688 73.4467L590.375 85.5895L577.062 73.4467L563.75 85.5895L550.438 73.4467L537.125 85.5895L523.812 73.4467L510.5 85.5895L497.188 73.4467L483.875 85.5895L470.562 73.4467L457.25 85.5895L443.938 73.4467L430.625 85.5895L417.312 73.4467L404 85.5895L390.688 73.4467L377.375 85.5895L364.062 73.4467L350.75 85.5895L337.438 73.4467L324.125 85.5895L310.812 73.4467L297.5 85.5895L284.188 73.4467L270.875 85.5895L257.562 73.4467L244.25 85.5895L230.938 73.4467L217.625 85.5895L204.312 73.4467L191 85.5895L177.688 73.4467L164.375 85.5895L151.062 73.4467L137.75 85.5895L124.438 73.4467L111.125 85.5895L97.8125 73.4467L84.5 85.5895L71.1875 73.4467L57.875 85.5895L44.5625 73.4467L31.25 85.5895L17.9375 73.4467L4.625 85.5895V0.589539Z"
              fill="url(#paint0_linear_1316_5478)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.625 0.589539H430.625H856.625H1282.62V85.5895L1269.31 73.4467L1256 85.5895L1242.69 73.4467L1229.38 85.5895L1216.06 73.4467L1202.75 85.5895L1189.44 73.4467L1176.12 85.5895L1162.81 73.4467L1149.5 85.5895L1136.19 73.4467L1122.88 85.5895L1109.56 73.4467L1096.25 85.5895L1082.94 73.4467L1069.62 85.5895L1056.31 73.4467L1043 85.5895L1029.69 73.4467L1016.38 85.5895L1003.06 73.4467L989.75 85.5895L976.438 73.4467L963.125 85.5895L949.812 73.4467L936.5 85.5895L923.188 73.4467L909.875 85.5895L896.562 73.4467L883.25 85.5895L869.938 73.4467L856.625 85.5895L843.312 73.4467L830 85.5895L816.688 73.4467L803.375 85.5895L790.062 73.4467L776.75 85.5895L763.438 73.4467L750.125 85.5895L736.812 73.4467L723.5 85.5895L710.188 73.4467L696.875 85.5895L683.562 73.4467L670.25 85.5895L656.938 73.4467L643.625 85.5895L630.312 73.4467L617 85.5895L603.688 73.4467L590.375 85.5895L577.062 73.4467L563.75 85.5895L550.438 73.4467L537.125 85.5895L523.812 73.4467L510.5 85.5895L497.188 73.4467L483.875 85.5895L470.562 73.4467L457.25 85.5895L443.938 73.4467L430.625 85.5895L417.312 73.4467L404 85.5895L390.688 73.4467L377.375 85.5895L364.062 73.4467L350.75 85.5895L337.438 73.4467L324.125 85.5895L310.812 73.4467L297.5 85.5895L284.188 73.4467L270.875 85.5895L257.562 73.4467L244.25 85.5895L230.938 73.4467L217.625 85.5895L204.312 73.4467L191 85.5895L177.688 73.4467L164.375 85.5895L151.062 73.4467L137.75 85.5895L124.438 73.4467L111.125 85.5895L97.8125 73.4467L84.5 85.5895L71.1875 73.4467L57.875 85.5895L44.5625 73.4467L31.25 85.5895L17.9375 73.4467L4.625 85.5895V0.589539Z"
              fill="url(#paint1_linear_1316_5478)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1316_5478"
              x="0.625"
              y="0.589539"
              width="1286"
              height="93"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1316_5478"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1316_5478"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1316_5478"
              x1="1062.12"
              y1="5.44668"
              x2="1062.12"
              y2="89.8395"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFEDF3" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1316_5478"
              x1="643.625"
              y1="85.5895"
              x2="643.625"
              y2="-10.8552"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D4059" />
              <stop offset="1" stopColor="#143A6C" />
            </linearGradient>
          </defs>
        </svg>
        <img src={logo} alt="logotype" className="header__logo" />
      </div>
    </header>
  );
};
